import { TinySliderSettings, tns } from "tiny-slider/src/tiny-slider";
import { PlansQ } from "@tawenda-npm/tawenda-plansq";
import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";
import { Page } from "./Page";

export class HomePage extends Page {
  public async start(): Promise<void> {
    await super.start();
    this.startContentListCarousel();
  }

  private startContentListCarousel() {
    const defaults = {
      items: 1,
      slideBy: "page",
      autoplay: true,
      controls: false,
      autoplayButtonOutput: false,
      responsive: {
        768: {
          items: 2,
        },

        1024: {
          items: 3,
        },
      },
    };

    document
      .querySelectorAll(".cards--carousel")
      .forEach((element: HTMLElement) => {
        if (element.classList.contains("cards--carousel-5")) {
          defaults.responsive["1024"].items = 5;
        }

        const options = {
          ...{ container: element },
          ...defaults,
        };
        tns(options as TinySliderSettings);
      });

    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
