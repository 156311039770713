import { Page } from "./Page";
import contactForm from "../libs/Contact";

export class ContactPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    contactForm();
  }
}
