interface MoreVideosInterface {
  success: boolean;
  html: string;
  next_url: string;
}

export class VideoSiblings {
  private alreadyClicked = false;

  private wrapper: HTMLDivElement;
  private container: HTMLDivElement;
  private url: string | undefined;
  private loadingText: string | undefined;
  private errorText: string | undefined;
  private currentText: string | undefined;

  constructor(private element: HTMLAnchorElement) {
    this.wrapper = this.element.closest("[data-video-siblings-container]");
    this.container = this.wrapper.querySelector(
      "[data-video-siblings]"
    ) as HTMLDivElement;
  }

  public async load(): Promise<void> {
    if (!this.alreadyClicked) await this.initLoad();
  }

  private async initLoad(): Promise<any> {
    this.url = this.element.dataset.loadMoreVideos;
    this.loadingText = this.element.dataset.loadingText;
    this.errorText = this.element.dataset.errorText;
    this.currentText = this.element.innerText;
    this.alreadyClicked = true;
    this.element.innerText = this.loadingText;

    this.loadData()
      .catch((err) => {
        console.debug(err);
        this.element.innerText = this.errorText;
      })
      .finally(() => {
        this.reset();
      });
  }

  private reset(): void {
    this.alreadyClicked = false;
  }

  private async loadData(): Promise<any> {
    if (!this.url) return;

    const response = await fetch(this.url);

    if (response.ok) {
      const data: MoreVideosInterface = await response.json();

      if (data.success) {
        this.container.insertAdjacentHTML("beforeend", data.html);

        if (data.next_url) {
          this.element.dataset.loadMoreVideos = data.next_url;
        } else {
          this.element.remove();
        }
        this.element.innerText = this.currentText;
      }
    }
  }
}

export default VideoSiblings;
