const initListFilterPromo = (): void => {
  document.querySelectorAll("[data-promo-url]").forEach((el: HTMLElement) => {
    el.addEventListener("click", (e: Event) => {
      e.preventDefault();
      window.location.href = el.dataset.promoUrl;
    });
  });
};

export default initListFilterPromo;
