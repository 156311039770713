import { Page } from "./Page";
import initListFilterPromo from "../libs/ListFilterPromo";
import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";

export class VideosListPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    initListFilterPromo();
    this.initMobileToggleEvents();
    this.seoDescription();
  }

  private initMobileToggleEvents() {
    const body = document.getElementsByTagName("body")[0];
    const filtersSidebar = document.getElementById("filters-sidebar");

    Array.from(document.getElementsByClassName("filter-toggle")).forEach(
      (el: HTMLElement) => {
        el.addEventListener("click", () => {
          filtersSidebar?.classList.toggle("sidebar-mobile--open");
          body.classList.toggle("overflow-hidden");
        });
      }
    );
  }

  private seoDescription(): void {
    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
