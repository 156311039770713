import { Page } from "./Page";
import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";

export class GeoPage extends Page {
  public async start(): Promise<void> {
    await super.start();
    GeoPage.seoDescription();
  }

  private static seoDescription(): void {
    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
