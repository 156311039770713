export class SubNavigation {
  private readonly apiUrl: string;
  private navigationElement: HTMLElement | undefined;

  constructor(private wrapper: HTMLElement) {
    this.apiUrl = wrapper.dataset.subnavigationWrapper;
    this.loadSubNavigation().catch(() => undefined);
  }

  private async loadSubNavigation(): Promise<any> {
    const response = await fetch(this.apiUrl);
    if (response.ok) {
      this.wrapper.innerHTML = await response.text();
      this.navigationElement = document.getElementsByTagName("header")[0];
      this.launchEvents();
    }
  }

  private onOpenMenu(): void {
    const toggle = document.getElementById("navigation__toggle");
    toggle?.addEventListener("click", () => {
      this.navigationElement?.classList.remove("closed");
    });
  }

  private onCloseMenu() {
    const backdrop = document.getElementById("navigation__backdrop");
    backdrop?.addEventListener("click", () => {
      this.navigationElement?.classList.add("closed");
    });
  }

  private dropdownToggle() {
    const dropdown = document.getElementsByClassName("dropdown");
    const dropdownArray = Array.from(dropdown);

    dropdownArray.forEach((el: Element) => {
      const toggle = el.querySelector(".dropdown__toggle");

      toggle?.addEventListener("click", (event: Event) => {
        event.preventDefault();
        el.classList.toggle("show");
      });
    });
  }

  private subNavigation(): void {
    const toggle = document.getElementsByClassName(
      "navigation__link--dropdown"
    );
    const toggleArray = Array.from(toggle);

    toggleArray.forEach((el: Element) => {
      const targetId = document.getElementById(
        <string>el.getAttribute("data-target")
      );

      el.addEventListener("mouseenter", () => {
        toggleArray.forEach((el: Element) => {
          const target = <string>el.getAttribute("data-target");
          const targetId = document.getElementById(target) as HTMLElement;
          targetId.style.display = "none";
        });

        targetId.style.display = "block";
      });

      targetId.addEventListener("mouseleave", () => {
        targetId.style.display = "none";
      });
    });
  }

  launchEvents(): void {
    this.onOpenMenu();
    this.onCloseMenu();
    this.dropdownToggle();
    this.subNavigation();
  }
}
