import { sleep } from "@tawenda-npm/tawenda-utils";

export class SearchForm {
  private wrapper: HTMLElement | null;
  private readonly input: HTMLInputElement | null;
  private toggleButton: HTMLButtonElement | null;

  constructor(private form: HTMLDivElement) {
    this.wrapper = document.getElementById("search-form");
    this.toggleButton = document.querySelector("[data-toggle-search]");
    this.input = this.form?.querySelector("input[data-search-url]");
  }

  public load(): void {
    this.wrapper?.classList.remove("search--open");
    this.toggleButton?.addEventListener("click", async () => {
      await this.showForm();
      this.form.addEventListener("submit", (e: Event) => this.onSubmit(e));
    });
  }

  private onSubmit(e: Event): void {
    e.preventDefault();

    if (
      this.input &&
      this.input.value &&
      this.input.value !== "" &&
      this.input.value !== " "
    ) {
      document.location.href = this.input.dataset.searchUrl.replace(
        "QUERY",
        encodeURI(this.input.value)
      );
    }
  }

  private async showForm(): Promise<void> {
    if (this.input) this.input.nodeValue = "";

    this.toggleButton?.querySelector("i")?.classList.toggle("fa-search");
    this.toggleButton?.querySelector("i")?.classList.toggle("fa-times");
    this.wrapper?.classList.toggle("search--open");

    await sleep(100);
    this.input?.focus();
  }
}
