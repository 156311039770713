import { TawendaFingerprintCheckDevice } from "@tawenda-npm/tawenda-fingerprint-check-device";
import { Page } from "./Page";

export class CheckDevicePage extends Page {
  public async start(): Promise<void> {
    await super.start();

    const checkDeviceHandler: HTMLElement = document.querySelector(
      "[data-check-device-url]"
    );
    if (checkDeviceHandler) {
      const checkDevice = new TawendaFingerprintCheckDevice(
        checkDeviceHandler.dataset.checkDeviceUrl,
        checkDeviceHandler.dataset.checkDeviceNext
      );
      checkDevice.redirectIfAllowed();
    }
  }
}
