import Cookies from "js-cookie";

export default class DalenysSubscription {
  cookie_name = "dlns";
  cookie_ttl = 2; // in days

  is_modal = false;
  is_banner = false;

  request_is_mobile = false;

  protected bannerSeeMoreButton: HTMLElement | undefined;
  protected bannerSeeLessButton: HTMLElement | undefined;
  protected bannerContentElement: HTMLElement | undefined;

  constructor(private handler: HTMLElement) {
    this.is_modal = handler.dataset.dalenysModal !== undefined;
    this.is_banner = handler.dataset.dalenysBanner !== undefined;

    if (this.is_banner) {
      this.bannerSeeMoreButton = this.handler.querySelector(
        "[data-dalenys-see-more]"
      );
      this.bannerSeeLessButton = this.handler.querySelector(
        "[data-dalenys-see-less]"
      );
      this.bannerContentElement = this.handler.querySelector(
        "[data-dalenys-content]"
      );
      this.request_is_mobile =
        this.handler.dataset.dalenysIsMobile.toLowerCase() == "true";
    }
  }

  protected hasCookie(): boolean {
    return Cookies.get(this.cookie_name) !== undefined;
  }

  protected setCookie(): void {
    Cookies.set(this.cookie_name, "1", {
      expires: this.cookie_ttl,
      path: "/",
    });
  }

  protected initEvents(): void {
    const remindElement: HTMLAnchorElement = this.handler.querySelector(
      "[data-remind-later]"
    );
    remindElement?.addEventListener("click", (e: Event) => {
      e.preventDefault();
      this.setCookie();
      this.hide();
    });

    if (this.is_banner) {
      if (!this.hasCookie()) this.showBannerContent();
      this.initBannerEvents();
    }
  }

  protected initBannerEvents(): void {
    this.bannerSeeLessButton?.addEventListener("click", (e: Event) => {
      e.preventDefault();
      this.hideBannerContent();
      this.setCookie();
    });
    this.bannerSeeMoreButton?.addEventListener("click", (e: Event) => {
      e.preventDefault();
      this.showBannerContent();
    });
  }

  public show(): void {
    if (!this.hasCookie()) {
      if (this.is_modal) this.showModal();
      this.initEvents();
    } else {
      this.hide();
    }
  }

  protected hide(): void {
    if (this.is_modal) this.removeModal();
    if (this.is_banner) {
      if (this.request_is_mobile) this.hideBannerContent();
      this.initBannerEvents();
    }
  }

  protected showModal() {
    this.handler.classList.add("dalenys-modal--open");
  }

  protected removeModal(): void {
    this.handler.remove();
  }

  protected showBanner(): void {
    if (!this.hasCookie()) {
      this.initEvents();
    } else {
      this.hide();
    }
  }

  protected showBannerContent(): void {
    this.bannerContentElement.style.display = "flex";
    this.bannerSeeMoreButton.style.display = "none";
  }

  protected hideBannerContent(): void {
    this.bannerContentElement.style.display = "none";
    this.bannerSeeMoreButton.style.display = "block";
  }
}
