import Cookies from "js-cookie";

interface ResultInterface {
  success: boolean;
  mode: "add" | "remove";
  message: string;
}

export class Favorites {
  constructor(private element: HTMLElement) {}

  public async init() {
    const favoriteUrl: string | undefined = this.element.dataset.favoriteGetUrl;
    if (!favoriteUrl) return;

    const response = await fetch(favoriteUrl);
    if (response.ok) {
      const result: ResultInterface = await response.json();
      if (result.success) {
        this.setFavoriteStatus(result.mode);
        this.initEvents();
      }
    }
  }

  private initEvents(): void {
    Array.from(this.element.children).forEach((el: Element) => {
      el.addEventListener("click", async () => {
        await this.changeState();
      });
    });
  }

  private async changeState(): Promise<any> {
    const changeUrl: string | undefined =
      this.element.dataset.favoriteChangeUrl;
    if (!changeUrl) return;

    const response = await fetch(changeUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "post",
      body: JSON.stringify({ mode: this.element.getAttribute("mode") }),
    });

    if (response.ok) {
      const result: ResultInterface = await response.json();
      if (result.success) this.setFavoriteStatus(result.mode);
    } else {
      this.setFavoriteStatus("error");
    }
  }

  private setFavoriteStatus(newStatus: "add" | "remove" | "error"): void {
    if (newStatus !== "error") this.element.setAttribute("mode", newStatus);

    const addButton = this.element.querySelector(
      "[data-favorite-add]"
    ) as HTMLButtonElement;
    const removeButton = this.element.querySelector(
      "[data-favorite-remove]"
    ) as HTMLButtonElement;

    if (newStatus === "remove") {
      addButton.style.display = "none";
      removeButton.style.display = "inherit";
    }

    if (newStatus === "add") {
      addButton.style.display = "inherit";
      removeButton.style.display = "none";
    }

    this.toggleError([addButton, removeButton], newStatus === "error");
  }

  private toggleError(
    elements: Array<HTMLButtonElement>,
    isError: boolean
  ): void {
    elements.forEach((el: HTMLButtonElement) => {
      if (!isError) {
        el.querySelector("i")?.classList.replace("fa-heart-broken", "fa-heart");
      } else {
        el.querySelector("i")?.classList.replace("fa-heart", "fa-heart-broken");
      }
    });
  }
}
