import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { sleep, switchClasses } from "@tawenda-npm/tawenda-utils";

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOUR = 60 * MINUTES;
const DAY = 24 * HOUR;

interface VideoInterface {
  id: number;
  score: number;
  votes: number;
  likeUrl: string;
}

interface localStorageLikeItem {
  pk: number;
  datetime: number;
}

export class Likes {
  video: VideoInterface | undefined;

  storageKey = "vl";
  dataJSON: any;

  canLike = true;

  constructor(private likeButton: HTMLAnchorElement) {
    this.dataJSON = {};

    if (
      likeButton &&
      likeButton.dataset.videoPk &&
      likeButton.dataset.voteScore &&
      likeButton.dataset.likeNbvotes &&
      likeButton.dataset.voteUrl
    ) {
      this.video = {
        id: parseInt(likeButton.dataset.videoPk),
        score: parseInt(likeButton.dataset.voteScore),
        votes: parseInt(likeButton.dataset.likeNbvotes),
        likeUrl: likeButton.dataset.voteUrl,
      };
    }

    try {
      this.initFromLocalStorage();
    } catch (err) {
      console.debug(err);
    }
  }

  private initFromLocalStorage(): void {
    if (!this.video) return;

    const storageValue: string | null = localStorage.getItem(this.storageKey);

    if (storageValue) {
      this.dataJSON = this.cleanOldLikesInStorage(
        Likes.decodeValue(storageValue)
      );
    }

    if (this.dataJSON[this.video.id]) {
      this.liked();
    }
  }

  public async like(): Promise<void> {
    if (!this.video || !this.canLike) return;

    const response = await fetch(this.video.likeUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "post",
      body: JSON.stringify({
        score: this.video.score,
        pk: this.video.id,
      }),
    });

    return response.ok ? this.liked() : this.alreadyLiked();
  }

  private liked(): void {
    if (!this.video) return;

    const nbVotesElement = this.likeButton.querySelector(
      "[data-nb-votes]"
    ) as HTMLSpanElement;
    ++this.video.votes;

    nbVotesElement.innerText = this.video.votes.toString();
    switchClasses(this.likeButton);

    if (localStorage) {
      this.dataJSON[this.video.id] = Date.now();
      localStorage.setItem(this.storageKey, Likes.encodeValue(this.dataJSON));
    }
  }

  private async alreadyLiked(): Promise<void> {
    if (!this.video) return;

    this.canLike = false;

    const nbVotesElement = this.likeButton.querySelector(
      "[data-nb-votes]"
    ) as HTMLSpanElement;

    const icon = this.likeButton.querySelector("i");

    if (nbVotesElement && icon) {
      if (nbVotesElement.dataset.alreadyVotedText) {
        nbVotesElement.innerText = nbVotesElement.dataset.alreadyVotedText;
      }
      switchClasses(icon);

      await sleep(2000);
      nbVotesElement.innerText = this.video.votes.toString();
      switchClasses(icon);
      this.canLike = true;
    }
  }

  private static encodeValue(value: JSON): string {
    return Base64.encode(JSON.stringify(value));
  }

  private static decodeValue(value: string): Array<localStorageLikeItem> {
    return JSON.parse(Base64.decode(value));
  }

  private cleanOldLikesInStorage(
    data: Array<localStorageLikeItem>
  ): Array<localStorageLikeItem> {
    Object.entries(data).forEach((item: Array<any>) => {
      if (item[1] <= Date.now() - DAY * 10) {
        delete data[item[0]];
      }
    });
    return data;
  }
}
