import { Page } from "./Page";
import { SortElements } from "../libs/SortElements";
import { tns } from "tiny-slider/src/tiny-slider";
import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";

export class SortedListPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    new SortElements().sort();
    SortedListPage.initCarousel();
    SortedListPage.seoDescription();
  }

  private static initCarousel(): void {
    document
      .querySelectorAll(".cards--carousel")
      .forEach((element: HTMLElement) => {
        tns({
          container: element,
          items: 1,
          slideBy: "page",
          autoplay: true,
          controls: false,
          autoplayButtonOutput: false,

          responsive: {
            540: {
              items: 2,
            },

            1024: {
              items: 3,
            },
          },
        });
      });
  }

  private static seoDescription(): void {
    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
