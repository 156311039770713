import { Page } from "./Page";
import { PlayerHandler } from "../libs/PlayerHandler";
import { Likes } from "../libs/Likes";
import { Favorites } from "../libs/Favorites";
import { VideoSiblings } from "../libs/VideoSiblings";
import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";

export class VideoDetailPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    const partiallyDisabledPlayer: HTMLElement = document.querySelector(
      "#video-player.partially-disabled"
    );
    const player: HTMLElement = document.querySelector(
      "[data-player-get-settings-url]"
    );

    if (player) {
      const redirectTargetUrl = player.dataset.alternateVideoUrl;
      await new PlayerHandler(player as HTMLElement).init();
      if (redirectTargetUrl) {
        player.addEventListener("ended", () => {
          window.location.href = redirectTargetUrl;
        });
        setTimeout(() => {
          window.location.href = redirectTargetUrl;
        }, 10000);
      }
    }

    const my18passSelector = document.querySelector(
      "[data-my18pass-free-video]"
    );
    my18passSelector?.addEventListener("click", (e: Event) => {
      (document.querySelector("my18pass-widget") as any).openDialog(e);
    });

    const likeButton: HTMLAnchorElement =
      document.querySelector("[data-vote-url]");
    if (likeButton) {
      const likeHandler = new Likes(likeButton);

      likeButton.addEventListener("click", async (e: Event) => {
        e.preventDefault();
        await likeHandler.like();
      });
    }

    document
      .querySelectorAll("[data-favorite-get-url]")
      .forEach(async (el: HTMLElement) => {
        await new Favorites(el).init();
      });

    document
      .querySelectorAll("a[data-load-more-videos]")
      .forEach((element: Element) => {
        element.addEventListener("click", async (e: Event) => {
          e.preventDefault();
          await new VideoSiblings(element as HTMLAnchorElement).load();
        });
      });

    document
      .querySelectorAll("[data-toggle-class]")
      .forEach((button: HTMLElement) => {
        button.addEventListener("click", () => {
          toggleClass(button);
        });
      });

    document
      .querySelectorAll("[data-toggle-text]")
      .forEach((element: HTMLElement) => {
        element.addEventListener("click", () => {
          toggleText(element);
        });
      });
  }
}
