import { HomePage } from "./HomePage";
import { VideosListPage } from "./VideosList";
import { Page } from "./Page";
import { SortedListPage } from "./SortedListPage";
import { ContactPage } from "./ContactPage";
import { VideoDetailPage } from "./VideoDetailPage";
import { UserRegisterPage } from "./UserRegisterPage";
import { UserEditPage } from "./UserEditPage";
import { HowToDisableAdBlockPage } from "./AdBlockHowToDisable";
import { GeoPage } from "./GeoPage";
import { CheckDevicePage } from "./CheckDevicePage";

const initPages = (): void => {
  const body = document.querySelector("body");
  let page;

  switch (body.getAttribute("data-page")) {
    case "home":
      page = new HomePage();
      break;

    case "how-to-disable-adblock": {
      page = new HowToDisableAdBlockPage();
      break;
    }

    case "videos-list": {
      page = new VideosListPage();
      break;
    }

    case "video-detail": {
      page = new VideoDetailPage();
      break;
    }

    case "sorted-list": {
      page = new SortedListPage();
      break;
    }

    case "geo": {
      page = new GeoPage();
      break;
    }

    case "user-register": {
      page = new UserRegisterPage();
      break;
    }

    case "user-edit": {
      page = new UserEditPage();
      break;
    }

    case "check-device": {
      page = new CheckDevicePage();
      break;
    }

    case "contact": {
      page = new ContactPage();
      break;
    }
  }

  if (!page) {
    page = new Page();
  }
  page.start();
};

export default initPages;
