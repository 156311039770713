import { Disclaimer } from "@tawenda-npm/tawenda-disclaimer";
import "lazysizes";
import { AdBlock } from "@tawenda-npm/tawenda-adblock";
import { PlansQ } from "@tawenda-npm/tawenda-plansq";
import { Popunder } from "@tawenda-npm/tawenda-popunder";
import { SearchForm } from "../libs/SearchForm";
import { SubNavigation } from "../libs/Navigation";
import { UserState } from "../libs/UserState";
import initNextUrl from "../libs/NextUrl";
import lazyFrame from "../libs/lazyFrame";
import DalenysSubscription from "../libs/DalenysSubscription";

export class Page {
  protected readonly disclaimer: Disclaimer;
  protected readonly popunder: Popunder;
  protected readonly dalenysModal: HTMLElement | undefined;
  protected readonly dalenysBanner: HTMLElement | undefined;

  constructor() {
    this.popunder = new Popunder(document.documentElement.dataset.popunderUrl);
    this.disclaimer = new Disclaimer();
    this.disclaimer.addEventListener("closed", async () => {
      await this.popunder.redirectToPopUnder();
    });
    this.dalenysModal = document.querySelector("[data-dalenys-modal]");
    this.dalenysBanner = document.querySelector("[data-dalenys-banner]");
  }

  public async start(): Promise<void> {
    await this.disclaimer.loadDisclaimer();
    await new AdBlock().showAegis();
    await this.popunder.setupDOM();

    const plansQDesktop: HTMLElement = document.querySelector(
      "[data-plans-q-desktop]"
    );
    if (plansQDesktop) new PlansQ(plansQDesktop).show();

    const plansQMobile: HTMLElement = document.querySelector(
      "[data-plans-q-mobile]"
    );
    if (plansQMobile) new PlansQ(plansQMobile).show();

    lazyFrame();
    initNextUrl();
    if (this.dalenysModal || this.dalenysBanner) {
      new DalenysSubscription(this.dalenysBanner ?? this.dalenysModal).show();
    }

    const userStateHandler: HTMLElement = document.querySelector(
      "[data-user-state-url]"
    );
    if (userStateHandler) {
      await new UserState(userStateHandler.dataset.userStateUrl).setupDOM();
    }

    const subNavigationWrapper = document.querySelector(
      "[data-subnavigation-wrapper]"
    );
    if (subNavigationWrapper) {
      new SubNavigation(subNavigationWrapper as HTMLElement);
    }

    const searchFormElement: HTMLInputElement =
      document.querySelector("[data-search]");
    if (searchFormElement) {
      new SearchForm(searchFormElement).load();
    }
  }
}
