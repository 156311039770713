const initNextUrl = (): void => {
  document
    .querySelectorAll("[data-next-url]")
    .forEach((el: HTMLAnchorElement) => {
      el.addEventListener("click", (e: Event) => {
        el.href = `${el.href}?next=${el.dataset.nextUrl}`;
        return e;
      });
    });
};

export default initNextUrl;
